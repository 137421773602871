@font-face {
  font-family: SegoeRegular;
  src: url("./fonts/Sagoe/SegoeUI.ttf") format("truetype");
}

@font-face {
  font-family: SegoeItalic;
  font-style: italic;
  src: url("fonts/Sagoe/SegoeUIItalic.ttf") format("truetype");
}

@font-face {
  font-family: SegoeBold;
  font-style: bold;
  src: url("fonts/Sagoe/SegoeUIBold.ttf") format("truetype");
}

@font-face {
  font-family: Segoe;
  font-style: light;
  src: url("fonts/Sagoe/SegoeUILight.ttf") format("truetype");
}

@font-face {
  font-family: SegoeBoldItalic;
  font-style: bold italic;
  src: url("fonts/Sagoe/SegoeUIBoldItalic.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
