@import 'styles/variables.module.scss';
@import 'styles/colors.module.scss';

.modal-text {
  text-align: center;
}

.modal-title {
  @extend .modal-text;
  font-size: 28px;
  font-family: 'Lato' !important;
}

.modal-content {
  @extend .modal-text;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: 'Lato' !important;
  overflow-x: hidden;
}

.modal-actions {
  justify-content: center !important;
  padding-bottom: 30px !important;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    padding-bottom: 0px !important;
  }
}

.modal-cancel-button {
  height: 37px;
  min-width: 64px !important;
  text-transform: none !important;
  box-shadow: none !important;
  border: 1px solid #e8e9eb !important;
  color: #989898 !important;
  background-color: white !important;
}
.modal-cancel-button:hover {
  background-color: white !important;
  color: #989898 !important;
}

.modal-cancel-button-importApartment {
  height: 36px !important;
  min-width: 64px !important;
  text-transform: none !important;
  box-shadow: none !important;
  border: 1px solid #e8e9eb !important;
  color: #989898 !important;
  background-color: white !important;
}
.modal-cancel-button-importApartment:hover {
  background-color: white !important;
  color: #989898 !important;
}

.modal-action-button {
  height: 36px;
  min-width: 77px !important;
  color: white !important;
  margin-left: 18px !important;
  text-transform: none !important;
  box-shadow: none !important;
  .MuiButton-label {
    padding: 5px;
  }
}
.modal-action-button:hover {
  color: white !important;
}
.modal-action-button:disabled {
  background-color: $DISABLED_LIGHT !important;
}
