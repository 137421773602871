$primary-light: #f6f8fb;
$on-primary-light: #414655;
$primary-border: #e0e5ef;
$primary: #e0e5ef;
$primary-hover: #d0d5de;
$on-primary: #414655;
$accent: #585bd7;
$on-accent: #fff;
$accent-light: #f6f8ff;
$on-general-sub: #6e748c;
$on-accent-light: #3a3c92;
$graph-first-color: #b3d4ff;
$graph-second-color: #c19ce7;
$graph-third-color: #767dbf;
$graph-fourth-color: #ffbdad;
$graph-fifth-color: #ffb947;
$graph-sixth-color: #ff8566;
$booked: #1c2f82;
$deleted: #747b93;
$expired: #464c62;
$canvas: #f8f8f9;
$on-general: #363b4c;
$on-general-border: #d3d4da;
$on-general-sub: #6e748c;
$rowColor: #edeeef;
$disabled: #f0f1f3;
$on-disabled: #b6b9c2;
