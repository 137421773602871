@import 'styles/colors.module.scss';
@import 'styles/variables.module.scss';
@import 'styles/main.scss';
@import 'styles/variables.scss';

.filled {
  .input-default {
    color: $EDITING_COLOR !important;
  }
  .small-label-font {
    color: $EDITING_COLOR !important;
    font-size: $SMALL_LABEL_FONT;
  }
}

.modify-apartment-form-container {
  .input-default {
    width: 200px;
  }
}

.input-default {
  @extend .bold;
  text-overflow: ellipsis;
  font-size: $BIGGER_FONT !important;
}

.input-table-filter {
  @extend .bold;
  text-overflow: ellipsis;

  .MuiInputBase-input {
    border-bottom-color: $BACKGROUNDS !important;
    margin-bottom: 7px;
  }

  .MuiInput-underline {
    &.Mui-disabled:before,
    &:before,
    &:hover:not(.Mui-disabled):before {
      border-bottom-style: solid !important;
      border-bottom-color: $BACKGROUNDS !important;
    }
  }

  .event-form-spacing {
    padding: 16px;
  }
  .event-form-spacing-edit {
    padding: 16px;
    padding-top: 50px;
  }
  .drop-zone-container {
    border: 2px dashed $DROPZONE_BORDER;
    flex-grow: 1;
    max-height: 45px;
    padding: 10px 20px;
    border-radius: 20px;
    margin-right: 42px;
  }
  .client-form-spacing .MuiInput-underline {
    &.Mui-disabled:before,
    &:before,
    &:hover:not(.Mui-disabled):before {
      border-bottom-style: solid !important;
      border-bottom-color: $DISABLED !important;
    }
  }

  .event-form-spacing {
    padding: 16px;
  }
  .drop-zone-container {
    border: 2px dashed $DROPZONE_BORDER;
    flex-grow: 1;
    max-height: 45px;
    padding: 10px 20px;
    border-radius: 20px;
    margin-right: 42px;
  }

  .file-input-bin-placeholder {
    width: 42px;
  }

  .positioned-file-input-image {
    z-index: 1;
    width: 15px;
    top: 88px;
    right: 50px;
    height: 15px;
    position: absolute;
  }

  .error-text-file-input {
    color: $ERROR_COLOR;
  }

  .very-small-font {
    font-size: $VERY_SMALL_FONT;
    color: $ERROR_COLOR;
    font-weight: $BOLD;
  }

  .MuiPickersDay-day {
    .MuiIconButton-label {
      .MuiTypography-body2 {
        font-size: 12px !important;
        font-family: 'Lato';
      }
    }
  }
  .MuiDialogActions-root {
    padding: 10px;
    .MuiButton-textPrimary:hover {
      background-color: transparent !important;
    }
    .MuiButton-text {
      padding: 0px;
    }
    .MuiButton-root {
      min-width: fit-content;
    }
  }
  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 16px !important;
  }

  .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 5px;
  }
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}

.MuiDialogActions-root {
  button:first-child {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 32px;
    min-width: 80px;
    background-color: white;
    border: 1px solid #e8e9eb;
    color: #989898;
    font-size: 12px;
  }
  button:last-child {
    background-color: $accent;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 32px;
    min-width: 32px;
    color: $on-accent;
    font-size: 12px;
  }
}

.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

//////////////////////////////////
.rc-time-picker-input {
  font-size: 14px !important;
  color: black !important;
  width: 40px !important;
  font-family: 'Lato';
  font-weight: 600;
  border: 0px !important;
  border-radius: 0px !important;
  padding-left: 5px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  margin-right: 5px;
  outline: none;
  cursor: pointer !important;
}

.rc-time-picker-input[disabled] {
  background-color: white !important;
  cursor: context-menu !important;
}

.rc-time-picker-panel-inner {
  top: 40px;
  height: 230px;
  width: 166px;
  box-shadow: none !important;
  border: 1px solid #e8e9eb !important;
  border-radius: 0px !important;
  left: -22px;
}
.rc-time-picker-panel-select:first-child {
  width: 83px !important;
  min-height: 228px !important;
  overflow-y: auto;
}
.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 16px;
}
.rc-time-picker-panel-select::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.02);
  border-left: 1px solid #e8e9eb;
}
.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.51);
  border: 4px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.rc-time-picker-panel-input-wrap {
  display: none;
}

.rc-time-picker-panel-select li {
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px !important;
  font-weight: 700;
}

.rc-time-picker-panel-select li:hover {
  background-color: $accent !important;
}
.rc-time-picker-panel-select:last-child {
  width: 82px !important;
  min-height: 228px !important;
  overflow-y: auto;
}

.time-range-label {
  font-size: 11px;
  position: relative;
  left: 2px;
  color: #989898;
}
.pencil-icon-time-picker {
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
}
.pencil-icon-time-picker-content {
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
  height: 0px;
  position: relative;
  display: flex;
  justify-content: end;
  top: -25px;
}

.single-time-picker {
  .rc-time-picker-panel-inner {
    left: 0px;
    @media (max-width: '1400px') {
      left: -70px;
    }
    @media (max-width: $BREAKPOINT_MD) {
      left: -5px;
    }
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      left: -5px;
    }
    width: 320px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 150px;
  }
  .rc-time-picker-panel-combobox {
    height: 136px;
  }
  .rc-time-picker-panel-select:first-child {
    min-height: 136px !important;
    width: 150px !important;
    padding-right: 10px;
  }
  .rc-time-picker-panel-select:last-child {
    width: 150px !important;
    min-height: 136px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .rc-time-picker-panel-select li {
    display: flex;
    justify-content: start;
    padding-left: 8px !important;
  }
  li.rc-time-picker-panel-select-option-selected {
    background-color: #e1f3f0;
    font-family: 'Lato';
  }
  .rc-time-picker-panel-select li:hover {
    color: white;
  }
  .rc-time-picker-panel-select::-webkit-scrollbar {
    width: 8px !important;
  }
  .rc-time-picker-panel-select::-webkit-scrollbar-track {
    border-left: 0px !important;
    border-right: 4px solid white;
    background-color: #e3e5eb;
  }
  .rc-time-picker-panel-select::-webkit-scrollbar-thumb {
    background-color: #ccd1d3;
    border-radius: 0px !important;
    border-right: 4px solid transparent;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
}
.single-time-picker-component {
  width: 100%;
  height: 30px;
  z-index: 2;
}
.doughnut-action {
  border-radius: 50%;
  height: 6px;
  width: 6px;
}

.crm-autocomplete-value-icon {
  padding-bottom: 12px;
}

.crm-autocomplete-option {
  &-icon {
    margin-right: 8px;
    &,
    & div {
      display: inline;
      margin-bottom: 0px;
      padding-bottom: 0;
    }
  }
}

.textfield-input {
  z-index: 1;
}
