@import './variables.scss';

$COLOR_PRIMARY: #000000;
$COLOR_SECONDARY: #ffffff;
$CARD_BACKGROUND: rgba(232, 233, 235, 0.38);
$CARD_BACKGROUND_LIGHT: #ffffff;
$DEFAULT_FONT_COLOR: $on-general;
$GREY_TEXT: #bdc1c5;
$TABLE_HEADER_COLOR: #66657c;
$DEFAULT_TEXT: #000000;
$DISABLED_LIGHT: #dfdfdf;
$DISABLED: $disabled;
$DISABLED_DARK: #43425d;
$BACKGROUND_PAGE: #ffffff;
$BACKGROUNDS: rgba(232, 233, 235, 0.6);
$BACKGROUNDS_TRANSPARENT: rgba(245, 245, 252, 0.4);
/**
* is required to use old variables like $LIBERO with the new ones like $graph-first-color because of this line
* color: colors[as?.toUpperCase()] || '#000',
* in ChartConstants.jsx line 175
*/
$COMPROMESSO: $graph-fifth-color;
$LIBERO: $graph-first-color;
$OPZIONATO: $graph-third-color;
$ROGITATO: $graph_sixth_color;
$PROPOSTA: $graph-fourth-color;
$BORDER_AVATAR: #e0e0e0;
$INTERESSE: $graph-second-color;
$SEARCH_BUTTON: #43425d;
$USER_BACKGROUND: #43425d;
$INPUT_COLOR: #333333;
$USER_DATA_COLOR: #ffffff;
$EDITING_COLOR: #f35959;
$ERROR_COLOR: #f35959;
$LIGHT_FONT_COLOR: rgba(77, 79, 82, 0.5);
$SELECT_BACKGROUND: #66657c;
$CALENDAR_HEADER: #a3a6b4;
$CALENDAR_TODAY: #fff;
$DROPZONE_BORDER: #c9c9c9;
$EVENT_DATA_COLOR: #52575d;
$EVENT_STORE_BACKGROUND: #f0bd48;
$EVENT_VIDEO_BACKGROUND: #64c0eb;
$EVENT_CALL_BACKGROUND: #84ce95;
$RISERVATO: #fdc132;
$RICHIESTO: #2c73d2;
$CANCELLATO: $deleted;
$EXPIRED: $expired;
$IN_CORSO: #9c9ca0;
$NON_CONFERMATO: #f35959;
$CONFERMATO: #f0bd48;
$CONTROFIRMATO: #84ce95;
$VALIDATO: #2c97c9;

:export {
  // Export the color palette to make it accessible to JS
  COLOR_PRIMARY: $COLOR_PRIMARY;
  COLOR_SECONDARY: $COLOR_SECONDARY;
  CARD_BACKGROUND: $CARD_BACKGROUND;
  GREY_TEXT: $GREY_TEXT;
  DEFAULT_TEXT: $DEFAULT_TEXT;
  CARD_BACKGROUND_LIGHT: $CARD_BACKGROUND_LIGHT;
  DEFAULT_FONT_COLOR: $DEFAULT_FONT_COLOR;
  DISABLED: $DISABLED;
  DISABLED_DARK: $DISABLED_DARK;
  BACKGROUND_PAGE: $BACKGROUND_PAGE;
  BACKGROUNDS: $BACKGROUNDS;
  BACKGROUNDS_TRANSPARENT: $BACKGROUNDS_TRANSPARENT;
  INTERESSE: $INTERESSE;
  LIBERO: $LIBERO;
  OPZIONATO: $OPZIONATO;
  ROGITATO: $ROGITATO;
  COMPROMESSO: $COMPROMESSO;
  PROPOSTA: $PROPOSTA;
  INTERESSE: $INTERESSE;
  SEARCH_BUTTON: $SEARCH_BUTTON;
  USER_BACKGROUND: $USER_BACKGROUND;
  USER_DATA_COLOR: $USER_DATA_COLOR;
  INPUT_COLOR: $INPUT_COLOR;
  EDITING_COLOR: $EDITING_COLOR;
  LIGHT_FONT_COLOR: $LIGHT_FONT_COLOR;
  SELECT_BACKGROUND: $SELECT_BACKGROUND;
  BORDER_AVATAR: $BORDER_AVATAR;
  ERROR_COLOR: $ERROR_COLOR;
  CALENDAR_HEADER: $CALENDAR_HEADER;
  CALENDAR_TODAY: $CALENDAR_TODAY;
  DROPZONE_BORDER: $DROPZONE_BORDER;
  TABLE_HEADER_COLOR: $TABLE_HEADER_COLOR;
  EVENT_STORE_BACKGROUND: $EVENT_STORE_BACKGROUND;
  EVENT_VIDEO_BACKGROUND: $EVENT_VIDEO_BACKGROUND;
  EVENT_CALL_BACKGROUND: $EVENT_CALL_BACKGROUND;
  RISERVATO: $RISERVATO;
  RICHIESTO: $RICHIESTO;
  CANCELLATO: $CANCELLATO;
  EXPIRED: $EXPIRED;
  graph_first_color: $graph-first-color;
  graph_second_color: $graph-second-color;
  graph_third_color: $graph-third-color;
  graph_fourth_color: $graph-fourth-color;
  graph_fifth_color: $graph-fifth-color;
  graph_sixth_color: $graph-sixth-color;
  accent: $accent;
  booked: $booked;
}
