@import 'styles/variables.module.scss';
@import 'styles/variables.scss';

.pageTemplate {
  .pageTemplate-content {
    margin-top: 48px;
  }
  .newsletter-banner {
    background-color: $accent;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: $SMALL_LABEL_FONT;
    font-family: 'Lato';
    color: #1e201f;
    font-weight: 400;
    min-width: 18.75rem;
    z-index: 9;
    max-width: 40.625rem;
    top: 4rem;
    @media (max-width: $BREAKPOINT_MD) {
      max-width: 31.25rem;
    }
    @media (max-width: $BREAKPOINT_SMARTPHONE) {
      max-width: 18.75rem;
      top: 3.625rem;
    }

    .tecma-icon {
      margin-right: 0.5rem;
    }
    .banner-text {
      line-height: normal;
      .subscribe-link {
        text-decoration: underline;
        cursor: pointer;
        font-weight: 400;
        &:hover {
          font-weight: 700;
        }
      }
    }
  }
}
