@import 'styles/colors.module.scss';
@import 'styles/variables.module.scss';
@import 'styles/variables.scss';

.avatar-wrapper {
  height: 100% !important;

  padding: 0px !important;
  // width:50px;
  color: $on-primary;
  .user-button {
    border-radius: 0px;
    height: 100% !important;
    padding: 0px !important;
    min-width: 50px;
    justify-content: start;
    @media (min-width: $BREAKPOINT_SMARTPHONE) {
      border-right: 1px solid $on-primary;
    }

    border-left: 0px;

    span {
      height: 100%;
      div {
        height: 100%;
        width: 50px;
        svg {
          padding: 13px;
          path {
            stroke: $on-primary;
          }
        }
      }
    }
  }
}
.no-border {
  border: 0px !important;
}

.logged-user-name {
  color: $on-primary;
  text-transform: capitalize;
  width: 100px !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  @media (max-width: $BREAKPOINT_SMARTPHONE) {
    display: none;
  }
}

.user-option-selection {
  width: 150px !important;
  .MuiMenuItem-root {
    padding-left: 12px !important;
    height: 48px;
    font-size: 14px !important;
  }
}
