@import 'styles/colors.module.scss';
@import 'styles/variables.module.scss';
@import 'styles/variables.scss';

.header-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 48px;
  background: $primary;
  z-index: 5;
  display: flex;
  /* flex-flow: row wrap; */
  justify-content: space-between;
}
.tecma-elements {
  display: flex;
  gap: 16px;
  .image-tecma-wrapper {
    display: flex;
    height: 100%;
    width: 56px;
    border-right: 2px solid $on-primary;
    .image-tecma {
      width: 24px;
      height: 24px;
      margin: auto;
      svg path {
        fill: black;
      }
    }
  }
  .crm-wrapper {
    display: flex;
    font-size: 14px;
    color: $on-primary;
    width: 85px;
    align-items: center;
  }
}
.desktop-elements {
  display: flex;
  .tecma-button.medium {
    height: 100%;
    background-color: $primary !important;
    color: $on-primary !important;
    &:focus {
      outline: unset !important;
    }
    .tecma-icon path {
      fill: $on-primary !important;
    }
  }
}

.card-bread {
  font-size: $BIG_FONT;
  color: $USER_BACKGROUND;
}

.card-title {
  color: white;
  font-size: $BIG_FONT !important;
  font-weight: $SEMI_BOLD;
}

.lang-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $BREAKPOINT_MD) {
    position: absolute;
    top: -50px;
    right: 0px;
    margin-left: 10px;
  }
}

.mobile-drawer {
  color: $on-primary !important;
  border-radius: 0px !important;
  border-left: 1px solid $on-primary !important;
  height: 100%;
  padding: 12px !important;
}

.no-border {
  border: 0px !important;
}
.corner-positioning {
  right: 0;
  left: unset !important;
}

.language-option-selection {
  width: 150px !important;
  .MuiMenuItem-root {
    padding-left: 0.75rem !important;
    height: 3rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center !important;
    span {
      font-size: 11px;
      font-weight: 300;
    }
  }
}
